<div class="wrapper">
    
    <footer class="site-footer text-light">
        <div class="container text-center mb-4">
            <img src="assets/logo01.png" alt="Logo" class="footer-logo mb-2" />
            <div class="footer-text-content">
                <h3 class="footer-widget-title mb-2">ST. JOSEPH'S INSTITUTE OF MANAGEMENT</h3>
                <p class="footer-address mb-1">No. 28/1 Primrose Road, (Off M.G. Road), Bangalore - 560025.</p>
                <p class="footer-line">.....<span class="line"></span>.....</p>
            </div>
        </div>

        <div class="sub-footer">
            <ul class="footer-links list-unstyled">
                <li><a href="http://bjes.org/" target="_blank" class="text-light">BJES INSTITUTIONS</a></li>
                <li><a href="https://sjim.edu.in/nirf-data.php" class="text-light">NIRF DATA</a></li>
                <li><a href="https://sjim.edu.in/contact.php" class="text-light">REQUEST INFO</a></li>
                <li><a href="https://sjim.edu.in/sitemap.php" class="text-light">SITE MAP</a></li>
                <li><a href="https://sjim.edu.in/contact.php" class="text-light">VISIT</a></li>
                <li><a href="https://sjim.edu.in/contact.php" class="text-light">CONTACT</a></li>
            </ul>
        
            <div class="footer-text">
                <p class="footer-text-content mb-1">
                    &copy; 2023 St. Joseph's Institute Of Management All rights reserved.
                </p>
                <p class="footer-text-details mb-0">
                    Designed and Maintained by
                    <a href="http://www.integro.co.in/" target="_blank" class="text-warning">
                        Integro Infotech And Consulting
                    </a>
                    | Powered By LinWays
                </p>
            </div>
        </div>
    </footer>
</div>
