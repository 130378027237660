<!-- HTML Structure -->
<section class="welcome-section">
    <div class="container">
        <div class="row">
            <div class="col-md-3 sidebar">
                <div class="triangle-bottomleft"></div>
                <nav>
                    <ul class="mcd-menu">
                        <li>
                            <a href="/faculty-research">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span style="cursor: pointer;">Faculty Research</span>
                            </a>
                        </li>
                        <li>
                            <a href="/student-research">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Student Research</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://sjim.edu.in/businessreviewindia.php">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Business Review</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://sjim.edu.in/JournalBusinessReview.php">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Journal Business Review</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://sjim.edu.in/researchcenter.php">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Research Center</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://sjim.irins.org/">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Publication Metrics</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

            <!-- main content -->
            <div class="col-md-9">
                <h2 class="section-title">Student Research</h2>
                <div class="welcome-wrap">
                    <p>
                        Students interested in research are encouraged to co-author with faculty members and thereby
                        present their work at conferences or publish journal articles. The newly introduced concept of a
                        3-credit course titled “Course of Independent Study (CIS)” further encourages students to
                        undertake original research works. The student research at SJIM is listed below.
                    </p>

                     <!-- Loading spinner -->
                     <div *ngIf="isLoading" class="loading-spinner">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
                            width="200" height="200"
                            style="shape-rendering: auto; display: block; background: rgb(255, 255, 255);"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g>
                                <circle fill="#e15b64" r="10" cy="50" cx="84">
                                    <animate begin="0s" keySplines="0 0.5 0.5 1" values="10;0" keyTimes="0;1"
                                        calcMode="spline" dur="0.25s" repeatCount="indefinite" attributeName="r">
                                    </animate>
                                    <animate begin="0s" values="#e15b64;#abbd81;#f8b26a;#f47e60;#e15b64"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="discrete" dur="1s"
                                        repeatCount="indefinite" attributeName="fill"></animate>
                                </circle>
                                <circle fill="#e15b64" r="10" cy="50" cx="16">
                                    <animate begin="0s" keySplines="0 0.5 0.5 1" values="0;0;10;10;10"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="r"></animate>
                                    <animate begin="0s" keySplines="0 0.5 0.5 1" values="16;16;16;50;84"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="cx"></animate>
                                </circle>
                                <circle fill="#f47e60" r="10" cy="50" cx="50">
                                    <animate begin="-0.25s" keySplines="0 0.5 0.5 1" values="0;0;10;10;10"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="r"></animate>
                                    <animate begin="-0.25s" keySplines="0 0.5 0.5 1" values="16;16;16;50;84"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="cx"></animate>
                                </circle>
                                <circle fill="#f8b26a" r="10" cy="50" cx="84">
                                    <animate begin="-0.5s" keySplines="0 0.5 0.5 1" values="0;0;10;10;10"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="r"></animate>
                                    <animate begin="-0.5s" keySplines="0 0.5 0.5 1" values="16;16;16;50;84"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="cx"></animate>
                                </circle>
                                <circle fill="#abbd81" r="10" cy="50" cx="16">
                                    <animate begin="-0.75s" keySplines="0 0.5 0.5 1" values="0;0;10;10;10"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="r"></animate>
                                    <animate begin="-0.75s" keySplines="0 0.5 0.5 1" values="16;16;16;50;84"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="cx"></animate>
                                </circle>
                            </g>
                        </svg>
                    </div>

                    <!-- Check if studentData is available and filter out empty entries -->
                    <ul *ngIf="!isLoading && studentData && studentData.length > 0">
                        <li *ngFor="let research of studentData">
                            <div *ngIf="research.Details" class="bullet-points">
                                <p [innerHTML]="research.Details"></p> <!-- Render Details with HTML using innerHTML -->
                            </div>
                        </li>
                    </ul>

                    <!-- If no student data available -->
                    <p *ngIf="studentData.length === 0">No student research data available.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Scroll up button -->
<a class="scroll-top-up" 
   (click)="scrollToTop()" 
   [ngClass]="{'visible': showScrollUpButton}">
    <img src="assets/arrow-up.png" alt="Scroll Up" />
</a>

