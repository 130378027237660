<header class="navbar-wrapper navbar-background">
    <div class="container px-0" style="padding-left: 0;">
        <div class="row">
            <!-- Logo Section -->
            <div class="col-md-6" style="padding: 0;">
                <div class="navbar-header">
                    <div class="navbar-brand">
                        <h1 style="margin: 0;">
                            <a href="https://sjim.edu.in/index.php">
                                <img src="assets/sjim_logo1.png" alt="logo" />
                            </a>
                        </h1>
                    </div>
                </div>
            </div>

            <!-- Header Top -->
            <div class="col-md-6" style="padding: 0;">
                <div class="header-topbar hidden-sm hidden-xs">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="topbar-info emboss">
                                <a href="https://sjim.edu.in/Grievance_Redressal_Portal.php">Grievance Redressal
                                    Portal</a>
                                <span></span>
                                <a href="https://sjim.directverify.in/student/#/app/request" target="_blank">Educational
                                    Verification & Transcripts</a>
                                <span></span>
                                <a href="https://sjim.linways.com/" target="_blank" style="color: yellow;">Linways</a>
                                <span></span>
                                <br />
                                <p style="margin-top: 5px;">
                                    <a href="https://sites.google.com/sjim.edu.in/sjim-student-page"
                                        target="_blank">Students</a>
                                    <span></span>
                                    <a href="https://sites.google.com/sjim.edu.in/sjim-faculty-page"
                                        style="font-size: 1em;">Faculty</a>
                                    <a href="https://sjim.edu.in/alumni.php">Alumni</a>
                                    <span></span>
                                </p>
                                <div class="social-links">
                                    <a href="https://www.facebook.com/SJIMOfficial/" target="_blank">
                                        <i class="fa-brands fa-facebook-square" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fschool%2F15140952%2Fadmin%2F" target="_blank">
                                        <i class="fa-brands fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.youtube.com/c/StJosephsInstituteofManagementSJIM"
                                        target="_blank">
                                        <i class="fa-brands fa-youtube-square" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.instagram.com/sjim_bengaluru/" target="_blank">
                                        <i class="fa-brands fa-instagram" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.aacsb.edu/" target="_blank" rel="noopener noreferrer">
                                        <img src="assets/aacsb_logo.png" alt="AASCB Logo" class="logo-image" />
                                    </a>
                                    <a href="https://www.aicte-india.org/" target="_blank" rel="noopener noreferrer">
                                        <img src="assets/aicte_logo.jpg" alt="AICTE Logo" class="logo-image" />
                                    </a>
                                    <a href="https://www.nirfindia.org/" target="_blank" rel="noopener noreferrer">
                                        <img src="assets/nirf_logo.png" alt="NIRF Logo" class="logo-image" />
                                    </a>
                                    <a href="https://www.nbaind.org/" target="_blank" rel="noopener noreferrer">
                                        <img src="assets/NBA_logo.png" alt="NBA Logo" class="logo-image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Navigation Menu -->
        <div class="row">
            <nav class="navbar navbar-expand-lg navbar-light">

                <!-- Hamburger Button -->
                <button class="navbar-toggler" type="button" (click)="toggleMenu()"
                    [attr.aria-expanded]="isMenuOpen ? 'true' : 'false'" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>


                <!-- Navigation Links -->
                <div class="collapse navbar-collapse" [ngClass]="{'show': isMenuOpen}" id="navbarNav">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item"><a class="nav-link home-link" href="https://sjim.edu.in/index.php">
                            <i class="fa fa-home" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://sjim.edu.in/">About</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/About.php">St. Joseph's Institute of Management</a>
                                </li>
                                <li><a href="https://sjim.edu.in/jesuits.php">The Jesuits</a></li>
                                <li><a href="https://sjim.edu.in/Vision_Mission.php">Vision & Mission</a></li>
                                <li><a href="https://sjim.edu.in/sjim-institutions.php">St. Joseph Institutions</a></li>
                                <li><a href="https://sjim.edu.in/directors.php">Director's Message</a></li>
                                <li><a href="https://sjim.edu.in/governing-council.php">Governing Council</a></li>
                                <li><a href="https://sjim.edu.in/Accreditations.php">Accreditations</a></li>
                                <li><a href="https://sjim.edu.in/pdf/mandatory_disclosure23.pdf">Mandatory
                                        Information</a></li>
                                <li><a href="https://sjim.edu.in/membership.php">Memberships</a></li>
                                <li><a href="https://sjim.edu.in/grievance.php">Grievance Committees</a></li>
                                <li><a href="https://sjim.edu.in/AICTE_Approval_Letter.php">AICTE Approval Letter</a>
                                </li>
                                <li><a href="https://sjim.edu.in/Feedback.php">Feedback</a></li>
                                <li><a href="https://sjim.edu.in/media-coverage.php">Media Coverage</a></li>
                                <li><a href="https://sjim.edu.in/sjimaward.php">SJIM Eminent Social Entrepreneur
                                        Award</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="https://sjim.edu.in/pgdm.php">Program</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/pgdm.php">Post Graduate Diploma in Management (PGDM) -
                                        Equivalent to MBA</a></li>
                                <li><a href="https://sjim.edu.in/phd.php">Docator of Philosophy(PhD program)</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://sjim.edu.in/pgdm_admission.php"
                                style="color: yellow;">Admissions</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/pgdm.php">Overview</a></li>
                                <li><a href="https://sjim.edu.in/pgdm_admission.php">Admissions</a></li>
                                <li><a href="https://sjim.edu.in/pgdm_rankings.php">Rankings</a></li>
                                <li><a href="https://sjim.edu.in/pgdm_curriculum.php">Curriculum</a></li>
                                <li><a href="https://sjim.edu.in/pgdm-fee-structure.php">Fee Structure</a></li>
                                <li><a href="https://sjim.edu.in/pgdm-Educational-Loan.php">Educational Loan</a></li>
                                <li><a href="https://sjim.edu.in/pgdm-Schloarships.php">Scholarships</a></li>
                                <li><a href="https://sjim.edu.in/pgdm-selection-criteria.php">Selection Criteria</a>
                                </li>
                                <li><a href="https://sjim.edu.in/students-handbook.php">Student's Handbook</a></li>
                                <li><a href="https://sjim.edu.in/pdf/SJIM_Prospectus_2024.pdf">Download Prospectus</a>
                                </li>
                                <li><a href="https://sjim.edu.in/pgdm-placement.php">Placements</a></li>
                                <li><a href="https://sjim.edu.in/PGDM_MBA.php">PGDM vs MBA</a></li>
                                <li><a href="https://sjim.edu.in/hostel-accommodation.php">Hostel Accommodation</a></li>
                                <li><a href="https://sjim.edu.in/faq.php">FAQs</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a class="nav-link"
                                href="https://sjim.edu.in/international.php">International</a></li>
                        <li class="nav-item">
                            <a class="link-nav">Life &#64; SJIM</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/life-sjim.php">Overview</a></li>
                                <li><a href="https://sjim.edu.in/Facilities.php">Facilities</a></li>
                                <li><a href="https://sjim.edu.in/sjim-blog.php">SJIM Blog</a></li>
                                <li><a href="https://sjim.edu.in/faculty_initiative_teaching.php">Faculty Initiatives on
                                        Teaching</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://sjim.edu.in/events.php">Events</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/leadership_conference25.php">International
                                        Conference</a></li>
                                <li><a href="https://sjim.edu.in/verve.php">Verve</a></li>
                                <li><a href="https://sjim.edu.in/pinnacle.php">Pinnacle</a></li>
                                <li><a href="https://sjim.edu.in/guestlecture.php">Guest Lectures</a></li>
                                <li><a href="https://sjim.edu.in/b-plan.php">B-Plan Competition</a></li>
                                <li><a href="https://sjim.edu.in/csr-activities.php">CSR Activities</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="link-nav">Faculty & Research</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/faculty.php">Faculty Directory</a></li>
                                <li><a href="https://sjim.edu.in/faculty_initiative_teaching.php">Faculty Initiatives on
                                        Teaching</a></li>
                                <li>
                                    <a href="https://sjim.edu.in/researchfaculty.php">Research</a>
                                    <ul class="research-submenu">
                                        <li><a href="/faculty-research">Faculty Research</a></li>
                                        <li><a href="/student-research">Student Research</a></li>
                                        <li><a href="https://sjim.edu.in/businessreviewindia.php">Business Research</a>
                                        </li>
                                        <li><a href="https://sjim.edu.in/researchcenter.php">Research Center</a></li>
                                        <li><a href="https://sjim.irins.org/">Publications Metrics</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://sjim.edu.in/pgdm-placement.php">Placements</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/pgdm_PlacementReports.php">Placement Reports</a></li>
                                <li><a href="https://sjim.edu.in/pgdm_Recruiters-Guide.php">Recruiters` Guide</a></li>
                                <li><a href="https://sjim.edu.in/pgdm_Recruiters.php">Our Recruiters</a></li>
                                <li><a href="https://sjim.edu.in/pgdm_IIP.php">Industry Internship Program (IIP)</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://sjim.edu.in/">Industry-Academia</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/mdp.php">MDPs / FDPs / PDWs</a></li>
                                <li><a href="https://sjim.edu.in/Industry-Academia.php">Industry-Academia connect</a>
                                </li>
                                <li><a href="https://sjim.edu.in/MOU.php">MOUs with Industry</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://sjim.edu.in/academic-clubs.php">Clubs</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/finance-club.php">Finance Club</a></li>
                                <li><a href="https://sjim.edu.in/marketing-club.php">Marketing Club</a></li>
                                <li><a href="https://sjim.edu.in/human-resources-club.php">Human Resources Club</a></li>
                                <li><a href="https://sjim.edu.in/operations-club.php">Operations Club</a></li>
                                <li><a href="https://sjim.edu.in/entrepreneurship-club.php">Entrepreneurship Club</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://sjim.edu.in/Catalyst-Center.php">Catalyst</a>
                            <ul>
                                <li><a href="https://sjim.edu.in/Catalyst-Events.php">Catalyst Events</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a class="nav-link" href="https://sjim.edu.in/library.php">Library</a></li>
                        <li class="nav-item"><a class="nav-link" href="https://sjim.edu.in/contact.php">Contact</a></li>
                    </ul>
                </div>
            </nav>
        </div>

    </div>
</header>