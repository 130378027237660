<div class="container">
  <div class="card">
    <p>For faculty positions write to :
      <b>
        <span class="title-word title-word-1">
          director&#64;sjim.edu.in
        </span>
      </b>
    </p>

    <div class="button-group">
      <button (click)="showContent('byName')" [class.active]="activeContent === 'byName'">BY NAME</button>
      <button (click)="showContent('byDomain')" [class.active]="activeContent === 'byDomain'">BY DOMAIN</button>
      <button (click)="showContent('visitingFaculty')" [class.active]="activeContent === 'visitingFaculty'">VISITING
        FACULTY</button>
    </div>

    <div class="container" *ngIf="activeContent === 'byName'">
      <table>
        <colgroup>
          <col style="width: 33.33%;">
          <col style="width: 33.33%;">
          <col style="width: 33.33%;">
        </colgroup>
        <thead>
          <tr>
            <th>A</th>
            <th>B</th>
            <th>C</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div *ngFor="let staff of nameGroups['A']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
            <td>
              <div *ngFor="let staff of nameGroups['B']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
            <td>
              <div *ngFor="let staff of nameGroups['C']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <th>D</th>
            <th>J</th>
            <th>L</th>
          </tr>
          <tr>
            <td>
              <div *ngFor="let staff of nameGroups['D']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
            <td>
              <div *ngFor="let staff of nameGroups['J']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
            <td>
              <div *ngFor="let staff of nameGroups['L']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <th>M</th>
            <th>N</th>
            <th>O</th>
          </tr>
          <tr>
            <td>
              <div *ngFor="let staff of nameGroups['M']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
            <td>
              <div *ngFor="let staff of nameGroups['N']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
            <td>
              <div *ngFor="let staff of nameGroups['O']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <th>R</th>
            <th>S</th>
            <th>V</th>
          </tr>
          <tr>
            <td>
              <div *ngFor="let staff of nameGroups['R']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
            <td>
              <div *ngFor="let staff of nameGroups['S']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
            <td>
              <div *ngFor="let staff of nameGroups['V']">
                <a [routerLink]="['/staff-info', staff.id]" class="name-link">
                  {{ staff.name }}
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    

    <div *ngIf="activeContent === 'byDomain'">

      <div class="container">
        <div class="row">
          <ng-container *ngFor="let staff of staffList; let i = index">
            <div class="col-md-4">
              <div class="person-details">
                <div class="person-info">
                  <img [src]="staff.profilePicture" alt="{{ staff.name }}" class="person-image" />
                  <div class="person-content">
                    <p class="pname">Name: {{ staff.name }}</p>
                    <p class="pdepartment">Department: {{ staff.department }}</p>
                    <a [routerLink]="['/staff-info/', staff.id]" class="view-profile-link">View Profile</a>
                  </div>
                </div>
              </div>
            </div>

            <ng-container *ngIf="(i + 1) % 3 === 0 && (i + 1) !== staffList.length">
              <div class="col-12">
                <hr />
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>


    </div>

    <div *ngIf="activeContent === 'visitingFaculty'">
      <div class="container-faculty">
        <div class="row">
          <div *ngFor="let visitingFaculty of faculty" class="col-md-4">
            <div class="faculty-details">
              <div class="faculty-content">
                <p class="name">{{ visitingFaculty.name }}</p>
                <p class="department">Department: {{ visitingFaculty.department }}</p>
              </div>
            </div>
            <hr class="card-separator">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>