import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffService } from '../../service/staff.service';
import { IResearch, IResearchResponse } from '../../models/view-profile.model';

@Component({
  selector: 'app-student-research',
  imports: [CommonModule],
  providers: [StaffService],
  templateUrl: './student-research.component.html',
  styleUrls: ['./student-research.component.css']
})
export class StudentResearchComponent implements OnInit {

  isScrolled = false;
  studentData: IResearch[] = [];
  formId = '36rBxCwiGkjZ2aBi2';
  staffIds: string[] = [
    '2', '3', '6', '12', '17', '18', '22', '24', '25', '26', '59', '63', '65',
    '68', '74', '82', '106', '110', '111', '112', '113', '117', '121', '129'
  ];
  previousScrollPosition: number = 0;
  showScrollUpButton: boolean = false;
  isLoading: boolean = true;

  constructor(private staffService: StaffService) { }

  ngOnInit() {
    // Fetch the research details when the component is initialized
    this.getStudentResearchDetails();
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // Listen to scroll events
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const currentScrollPosition = window.scrollY;
    const halfPageHeight = document.documentElement.scrollHeight / 2;

    // Show button if scrolled past half of the page
    if (currentScrollPosition > halfPageHeight) {
      this.showScrollUpButton = true;
    } else {
      this.showScrollUpButton = false;
    }

    // Update previous scroll position
    this.previousScrollPosition = currentScrollPosition;
  }

  // Scroll to top functionality
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getStudentResearchDetails() {
    this.staffService.getResearchDetailsForAllStaff(this.formId, this.staffIds).subscribe(
      (response: IResearchResponse[]) => {
        // Flatten the response and filter out research without the "Yes" condition for "Are any co-authors SJIM students?"
        this.studentData = response.flatMap((researchResponse) =>
          researchResponse.data
            .filter(research => research["Are any co-authors SJIM students?"] === "Yes" &&
              research["Details"] !== "No student research data is available")
            .map(research => {
              const details = research["Details"] || "";
              const yearOfPublication = research["Year of Publication"] || "";
              const nameMatch = details.match(/^(.*?)(?:\s*\(\d{4}\))?$/); // Extract name before the year

              return {
                "Are any co-authors SJIM students?": research["Are any co-authors SJIM students?"],
                "Details": details, // Keep the full details
                "Year of Publication": yearOfPublication,
                "Name": nameMatch ? nameMatch[1].trim() : "", // Extracted name part
              };
            })
        );

        // Further filter to ensure valid "Year of Publication"
        this.studentData = this.studentData.filter(research =>
          research.Details && research["Year of Publication"]?.length === 4
        );

        // Sort by year in descending order
        this.studentData = this.studentData.sort((a, b) => {
          const yearA = parseInt(a["Year of Publication"] || "0", 10);
          const yearB = parseInt(b["Year of Publication"] || "0", 10);
          return yearB - yearA;
        });

        if (this.studentData.length === 0) {
          this.studentData = [{
            "Details": "No student research data available",
            "Are any co-authors SJIM students?": "No"
          }];
        }

        console.log('Student Research Details:', this.studentData);

        // Set loading to false after processing
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching student research details:', error);
        this.isLoading = false;
      }
    );
  }

}
