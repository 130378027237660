import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { StaffService } from '../../service/staff.service';
import { IAwardResponse, ICertificationsResponse, ICProgramResponse, ICProjectResponse, IEducationResponse, IInstitutesResponse, IPExpResponse, IResearch, IResearchResponse, StaffData } from '../../models/view-profile.model';

export enum PublicationType {
  JournalArticle = "Journal article",
  Book = "Book or book chapter",
  CaseStudy = "Case study",
  Conference = "Conference",
  PopularPress = "Newspaper or magazine article"
}

@Component({
  selector: 'app-view-profile',
  imports: [CommonModule],
  providers: [StaffService],
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfileComponent implements OnInit {

  staffDataList: StaffData[] = [];
  activeTab: string = 'qualifications';
  staff?: StaffData;
  educationData: IEducationResponse | undefined;
  certificationsData: ICertificationsResponse | undefined;
  journalData: IResearchResponse | undefined;
  booksData: IResearchResponse | undefined;
  caseStudiesData: IResearchResponse | undefined;
  conferencesData: IResearchResponse | undefined;
  popularPressData: IResearchResponse | undefined;
  consultancyProjectData: ICProjectResponse | undefined;
  consultancyProgramData: ICProgramResponse | undefined;
  institutesData: IInstitutesResponse | undefined;
  pastExprienceData: IPExpResponse | undefined;
  awrdGrantsData: IAwardResponse | undefined;

  // Mapping form IDs
  formIds = {
    education: 'kBy8knEadNzwfe94A',
    certifications: 'R5cfS6eDYgNqpcLgr',
    consultancyProject: 'e4ucW5x6RdCBYgceC',
    consultancyProgram: 'YNPuQv3yAYZuz6kb3',
    institutes: '4AwBua6rhbBeoGRdA',
    pastExperience: 'LPgnjgekSE8D2ZGoS',
    awards: 'NjJm3udjaCeeWCM7W'
  };


  FORM_ID = '36rBxCwiGkjZ2aBi2';
  staffIds: string[] = [
    '2', '3', '6', '12', '17', '18', '22', '24', '25', '26', '59', '63', '65',
    '68', '74', '82', '106', '110', '111', '112', '113', '117', '121', '129'
  ];

  isDetailAvailable: boolean = false;
  isValidStaffId: boolean = true;

  constructor(
    private staffService: StaffService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const staffId = params.get('id');
      if (staffId && this.staffIds.includes(staffId)) {
        this.loadStaffData(staffId);
      } else {
        this.isValidStaffId = false;
      }
    });

  }

  redirectToProfile(linkedinUrl: string): void {
    if (linkedinUrl) {
      window.open(linkedinUrl, '_blank');
    } else {
      window.location.href = window.location.href;
    }
  }

  loadStaffData(staffId: string): void {
    this.staffService.getFormDetails(staffId).subscribe(response => {
      // console.log('API Response:', response); 
      if (response.data && response.data.length) {
        this.staffDataList = response.data.map(item => ({
          ...item,
          Name: item["Name"],
          Designation: item["Designation"],
          "SJIM Email Address": item["SJIM Email Address"],
          "Room Number": item["Room Number"],
          "LinkedIn Profile": item["LinkedIn Profile"],
          "Courses Taught at SJIM": item["Courses Taught at SJIM"],
          "Research Interests": item["Research Interests"],
          profilePicture: item["profilePicture"]
        }));
        // console.log('Staff data for display:', this.staffDataList); 
        this.isDetailAvailable = true;

        // Load data using form ID mappings
        this.loadEducationData(this.formIds.education, staffId);
        this.loadCertificationsData(this.formIds.certifications, staffId);
        this.loadResearchData(staffId, PublicationType.JournalArticle);
        this.loadResearchData(staffId, PublicationType.Book);
        this.loadResearchData(staffId, PublicationType.CaseStudy);
        this.loadResearchData(staffId, PublicationType.Conference);
        this.loadResearchData(staffId, PublicationType.PopularPress);
        this.loadProjectData(this.formIds.consultancyProject, staffId);
        this.loadProgramData(this.formIds.consultancyProgram, staffId);
        this.loadInstitutesData(this.formIds.institutes, staffId);
        this.loadPastExprienceData(this.formIds.pastExperience, staffId);
        this.loadAwardData(this.formIds.awards, staffId);
      }
    });
  }

  loadEducationData(formId: string, staffId: string): void {
    this.staffService.getEducationDetails(formId, staffId)
      .subscribe(data => {
        this.educationData = data;
      });
  }

  loadCertificationsData(formId: string, staffId: string): void {
    this.staffService.getCertificationsDetails(formId, staffId)
      .subscribe(data => {
        this.certificationsData = data;
      });
  }

  loadResearchData(staffId: string, publicationType: PublicationType): void {
    this.staffService.getResearchDetails(this.FORM_ID, staffId)
      .subscribe(data => {

        const filteredData = data.data.filter(item => item['Publication Type'] === publicationType);

        const filteredResearchResponse: IResearchResponse = {
          headers: data.headers,
          data: filteredData
        };

        switch (publicationType) {
          case PublicationType.JournalArticle:
            this.journalData = filteredResearchResponse;
            break;
          case PublicationType.Book:
            this.booksData = filteredResearchResponse;
            break;
          case PublicationType.CaseStudy:
            this.caseStudiesData = filteredResearchResponse;
            break;
          case PublicationType.Conference:
            this.conferencesData = filteredResearchResponse;
            break;
          case PublicationType.PopularPress:
            this.popularPressData = filteredResearchResponse;
            break;
          default:
            break;
        }
      }, error => {
        console.error('Error fetching research data:', error);
      });
  }


  loadProjectData(formId: string, staffId: string): void {
    this.staffService.getCProjectDetails(formId, staffId)
      .subscribe(data => {
        this.consultancyProjectData = data;
      });
  }

  loadProgramData(formId: string, staffId: string): void {
    this.staffService.getCProgramDetails(formId, staffId)
      .subscribe(data => {
        this.consultancyProgramData = data;
      });
  }

  loadInstitutesData(formId: string, staffId: string): void {
    this.staffService.getInstitutesDetails(formId, staffId)
      .subscribe(data => {
        this.institutesData = data;
      });
  }

  loadPastExprienceData(formId: string, staffId: string): void {
    this.staffService.getPastExperienceDetails(formId, staffId)
      .subscribe(data => {
        this.pastExprienceData = data;
      });
  }

  loadAwardData(formId: string, staffId: string): void {
    this.staffService.getAwardsDetails(formId, staffId)
      .subscribe(data => {
        this.awrdGrantsData = data;
      });
  }

  formatDateRange(startDate: string, endDate: string): string {
    const startYear = startDate ? startDate.split('-').pop() : '';
    const endYear = endDate ? endDate.split('-').pop() : '';

    if (startYear && endYear) {
      return `${startYear}-${endYear}`;
    } else if (startYear) {
      return `${startYear}-`;
    } else if (endYear) {
      return `-${endYear}`;
    } else {
      return '';
    }
  }

  concatenateInfo(memberOf: string, anyOtherInformation: string): string {
    return `${memberOf}. ${anyOtherInformation}`;
  }


  getYear(dateString: string): string {
    const parts = dateString.split('-');
    if (parts.length === 3) {
      if (parts[0].length === 4) {
        return parts[0];
      } else if (parts[2].length === 4) {
        return parts[2];
      }
    }
    throw new Error("Invalid date format. Expected 'dd-mm-yyyy' or 'yyyy-mm-dd'");
  }

  showTab(tab: string): void {
    this.activeTab = tab;
  }

  getMetrics(item: IResearch): string {
    const ABDC: string = item["Is  the publication listed in ABDC?"] || '';
    const WOS: string = item["Is the publication listed in Web-of-Science?"] || '';
    const SC: string = item["Is the publication listed in Scopus?"] || '';

    let metrics: string[] = [];

    // Include all ABDC categories
    if (["ABDC-A*", "ABDC-A", "ABDC-B", "ABDC-C", "ABDC-D"].includes(ABDC)) {
      metrics.push(ABDC);
    }

    if (WOS === 'Yes') {
      metrics.push('Web-of-Science');
    }

    if (SC === 'Yes') {
      metrics.push('Scopus-indexed');
    }

    return metrics.join('<br>');
  }

}