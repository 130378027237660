import { Routes } from '@angular/router';
import { StaffProfileComponent } from './staff-info/staff-profile/staff-profile.component';
import { ViewProfileComponent } from './staff-info/view-profile/view-profile.component';
import { FacultyResearchComponent } from './research-page/faculty-research/faculty-research.component';
import { StudentResearchComponent } from './research-page/student-research/student-research.component';

export const routes: Routes = [
    { path: '', component: StaffProfileComponent },
    { path: 'staff-info/:id', component: ViewProfileComponent },
    { path: 'faculty-research', component: FacultyResearchComponent },
    { path: 'student-research', component: StudentResearchComponent }
];
