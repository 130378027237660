<section class="welcome-section">
    <div class="container">
        <div class="row">
             <!-- Sidebar Navigation -->
             <div class="col-md-3 sidebar">
                <div class="triangle-bottomleft"></div>
                <nav>
                    <ul class="mcd-menu">
                        <li>
                            <a href="/faculty-research">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Faculty Research</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="scrollToSection('Publications in Journals')">
                                <img src="assets/arrow-left.png" alt="arrow-left"
                                    style="margin-top: -3px;width: 8%; margin-left: 2em; width: 20px;" />
                                <span style="cursor: pointer;">Publications in Journals</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="scrollToSection('Books & Book Chapters')">
                                <img src="assets/arrow-left.png" alt="arrow-left"
                                    style="margin-top: -3px;width: 8%; margin-left: 2em; width: 20px;" />
                                <span style="cursor: pointer;">Books & Book Chapters</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="scrollToSection('Case Studies in Public Domain')">
                                <img src="assets/arrow-left.png" alt="arrow-left"
                                    style="margin-top: -3px;width: 8%; margin-left: 2em; width: 20px;" />
                                <span style="cursor: pointer;">Case Studies in Public Domain</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="scrollToSection('Conferences')">
                                <img src="assets/arrow-left.png" alt="arrow-left"
                                    style="margin-top: -3px;width: 8%; margin-left: 2em; width: 20px;" />
                                <span style="cursor: pointer;">Conferences</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="scrollToSection('Popular Press')">
                                <img src="assets/arrow-left.png" alt="arrow-left"
                                    style="margin-top: -3px;width: 8%; margin-left: 2em; width: 20px;" />
                                <span style="cursor: pointer;">Popular Press</span>
                            </a>
                        </li>

                        <li>
                            <a href="/student-research">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Student Research</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://sjim.edu.in/businessreviewindia.php">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Business Review</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://sjim.edu.in/JournalBusinessReview.php">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Journal Business review</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://sjim.edu.in/researchcenter.php">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Research Center</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://sjim.irins.org/">
                                <img src="assets/arrow-left.png" alt="arrow-left" />
                                <span>Publication Metrics</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

            <!-- Main Content -->
            <div class="col-md-9">
                <h2 class="section-title">Faculty Research</h2>
                <div class="welcome-wrap">
                    <p>Our faculty engage in a range of research activities. Below, we list a selection of faculty
                        publications.</p>

                    <!-- Loading spinner -->
                    <div *ngIf="isLoading" class="loading-spinner">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
                            width="200" height="200"
                            style="shape-rendering: auto; display: block; background: rgb(255, 255, 255);"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g>
                                <circle fill="#e15b64" r="10" cy="50" cx="84">
                                    <animate begin="0s" keySplines="0 0.5 0.5 1" values="10;0" keyTimes="0;1"
                                        calcMode="spline" dur="0.25s" repeatCount="indefinite" attributeName="r">
                                    </animate>
                                    <animate begin="0s" values="#e15b64;#abbd81;#f8b26a;#f47e60;#e15b64"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="discrete" dur="1s"
                                        repeatCount="indefinite" attributeName="fill"></animate>
                                </circle>
                                <circle fill="#e15b64" r="10" cy="50" cx="16">
                                    <animate begin="0s" keySplines="0 0.5 0.5 1" values="0;0;10;10;10"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="r"></animate>
                                    <animate begin="0s" keySplines="0 0.5 0.5 1" values="16;16;16;50;84"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="cx"></animate>
                                </circle>
                                <circle fill="#f47e60" r="10" cy="50" cx="50">
                                    <animate begin="-0.25s" keySplines="0 0.5 0.5 1" values="0;0;10;10;10"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="r"></animate>
                                    <animate begin="-0.25s" keySplines="0 0.5 0.5 1" values="16;16;16;50;84"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="cx"></animate>
                                </circle>
                                <circle fill="#f8b26a" r="10" cy="50" cx="84">
                                    <animate begin="-0.5s" keySplines="0 0.5 0.5 1" values="0;0;10;10;10"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="r"></animate>
                                    <animate begin="-0.5s" keySplines="0 0.5 0.5 1" values="16;16;16;50;84"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="cx"></animate>
                                </circle>
                                <circle fill="#abbd81" r="10" cy="50" cx="16">
                                    <animate begin="-0.75s" keySplines="0 0.5 0.5 1" values="0;0;10;10;10"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="r"></animate>
                                    <animate begin="-0.75s" keySplines="0 0.5 0.5 1" values="16;16;16;50;84"
                                        keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s" repeatCount="indefinite"
                                        attributeName="cx"></animate>
                                </circle>
                            </g>
                        </svg>
                    </div>

                    <!-- Display Research Data -->
                    <div *ngIf="!isLoading && researchData && researchData.length > 0">
                        <!-- Display Publications in Journals Section -->
                        <div id="Publications in Journals">
                            <h3 class="section-title-with-spacing">Publications in Journals</h3>
                            <div *ngFor="let research of researchData">
                                <div *ngIf="research['Publication Type'] === 'Journal article'">
                                    <ul>
                                        <li class="research-details">
                                            {{ research['Details'] }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <br>
                        </div>

                        <!-- Display Books & Book Chapters Section -->
                        <div id="Books & Book Chapters">
                            <h3 class="section-title-with-spacing">Books & Book Chapters</h3>
                            <div *ngFor="let research of researchData">
                                <div *ngIf="research['Publication Type'] === 'Book or book chapter'">
                                    <ul>
                                        <li class="research-details">
                                            {{ research['Details'] }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <br>
                        </div>

                        <!-- Display Case Studies in Public Domain Section -->
                        <div id="Case Studies in Public Domain">
                            <h3 class="section-title-with-spacing">Case Studies in Public Domain</h3>
                            <div *ngFor="let research of researchData">
                                <div *ngIf="research['Publication Type'] === 'Case study'">
                                    <ul>
                                        <li class="research-details">
                                            {{ research['Details'] }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <br>
                        </div>

                        <!-- Display Conferences Section -->
                        <div id="Conferences">
                            <h3 class="section-title-with-spacing">Conferences</h3>
                            <div *ngFor="let research of researchData">
                                <div *ngIf="research['Publication Type'] === 'Conference'">
                                    <ul>
                                        <li class="research-details">
                                            {{ research['Details'] }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <br>
                        </div>

                        <!-- Display Popular Press Section -->
                        <div id="Popular Press">
                            <h3 class="section-title-with-spacing">Popular Press</h3>
                            <div *ngFor="let research of researchData">
                                <div *ngIf="research['Publication Type'] === 'Newspaper or magazine article'">
                                    <ul>
                                        <li class="research-details">
                                            {{ research['Details'] }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <br>
                        </div>

                    </div>

                    <!-- No Data Available -->
                    <div *ngIf="!researchData || researchData.length === 0">
                        <p>No research data available.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Scroll up button -->
<a href="/faculty-research#top" 
   class="scroll-top-up" 
   (click)="scrollToTop()" 
   [ngClass]="{'visible': showScrollUpButton}">
    <img src="assets/arrow-up.png" alt="Scroll Up" />
</a>
