<div class="container">
    <div *ngIf="!isValidStaffId" style="align-items: center; justify-content: center;">
        <h3>Staff Details Not Found</h3>
    </div>
    <div *ngIf="!isDetailAvailable && isValidStaffId"
        style="align-items: center; justify-content: center; padding: 50px">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="200"
            height="200" style="shape-rendering: auto; display: block; background: rgb(255, 255, 255);"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g>
                <circle fill="#e15b64" r="10" cy="50" cx="84">
                    <animate begin="0s" keySplines="0 0.5 0.5 1" values="10;0" keyTimes="0;1" calcMode="spline"
                        dur="0.25s" repeatCount="indefinite" attributeName="r"></animate>
                    <animate begin="0s" values="#e15b64;#abbd81;#f8b26a;#f47e60;#e15b64" keyTimes="0;0.25;0.5;0.75;1"
                        calcMode="discrete" dur="1s" repeatCount="indefinite" attributeName="fill"></animate>
                </circle>
                <circle fill="#e15b64" r="10" cy="50" cx="16">
                    <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s"
                        repeatCount="indefinite" attributeName="r"></animate>
                    <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s"
                        repeatCount="indefinite" attributeName="cx"></animate>
                </circle>
                <circle fill="#f47e60" r="10" cy="50" cx="50">
                    <animate begin="-0.25s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s"
                        repeatCount="indefinite" attributeName="r"></animate>
                    <animate begin="-0.25s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s"
                        repeatCount="indefinite" attributeName="cx"></animate>
                </circle>
                <circle fill="#f8b26a" r="10" cy="50" cx="84">
                    <animate begin="-0.5s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s"
                        repeatCount="indefinite" attributeName="r"></animate>
                    <animate begin="-0.5s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s"
                        repeatCount="indefinite" attributeName="cx"></animate>
                </circle>
                <circle fill="#abbd81" r="10" cy="50" cx="16">
                    <animate begin="-0.75s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s"
                        repeatCount="indefinite" attributeName="r"></animate>
                    <animate begin="-0.75s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1s"
                        repeatCount="indefinite" attributeName="cx"></animate>
                </circle>
                <g></g>
            </g><!-- [ldio] generated by https://loading.io -->
        </svg>
    </div>
    <div class="profile-container" *ngIf="isDetailAvailable && isValidStaffId">
        <div class="card mb-4 profile-card">

            <div class="profile-details" *ngFor="let staff of staffDataList">
                <div class="profile-left">
                    <img [src]="staff.profilePicture" alt="{{ staff.Name }}" class="profile-image" />
                </div>
                <div class="profile-right">
                    <ul>
                        <li><span class="label">Name:</span> {{ staff.Name }}</li>
                        <li><span class="label">Designation:</span> {{ staff.Designation }}</li>
                        <li><span class="label">Administrative Role:</span> {{ staff['Administrative Role'] }}</li>
                        <li><span class="label">SJIM Email Address:</span> {{ staff['SJIM Email Address'] }}</li>
                        <li><span class="label">Room Number:</span> {{ staff['Room Number'] }}</li>
                        <li>
                            <span class="label">LinkedIn Profile:</span>
                            <a (click)="redirectToProfile(staff['LinkedIn Profile'])"
                                style="color: #921837; cursor: pointer;">
                                LinkedIn Profile
                            </a>
                        </li>
                        <li><span class="label">Courses Taught at SJIM:</span> {{ staff['Courses Taught at SJIM'] }}
                        </li>
                        <li><span class="label">Research Interests:</span> {{ staff['Research Interests'] }}</li>
                    </ul>
                </div>
            </div>

            <!-- Buttons for Tabs -->
            <div class="profile-buttons button-group">
                <button (click)="showTab('qualifications')"
                    [class.active]="activeTab === 'qualifications'">Qualifications</button>
                <button (click)="showTab('research')" [class.active]="activeTab === 'research'">Research</button>
                <button (click)="showTab('consultancy')"
                    [class.active]="activeTab === 'consultancy'">Consultancy</button>
                <button (click)="showTab('pastExperience')" [class.active]="activeTab === 'pastExperience'">Past
                    Experience</button>
                <button (click)="showTab('awardsAndGrants')" [class.active]="activeTab === 'awardsAndGrants'">Awards And
                    Grants</button>
            </div>

            <!-- Qualifications Tab -->
            <div *ngIf="activeTab === 'qualifications'">

                <ng-container *ngIf="educationData && educationData.data && educationData.data.length > 0">
                    <!-- Education Table -->
                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Education</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year</th>
                                <th>Name of Academic Degree</th>
                                <th>Specialization</th>
                                <th>University</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let edu of educationData?.data">
                                <td>{{ edu['Year']}}</td>
                                <td>{{ edu['Name of Academic Degree'] }}</td>
                                <td>{{ edu['Specialization'] }}</td>
                                <td>{{ edu['University'] }}</td>
                                <td>{{ edu['Details'] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <ng-container
                    *ngIf="certificationsData && certificationsData.data && certificationsData.data.length > 0">
                    <!-- Certifications Table -->
                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Certifications</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year</th>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Organized by</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cert of certificationsData?.data">
                                <td>{{ cert['Year'] }}</td>
                                <td>{{ cert['Type of Training'] }}</td>
                                <td>{{ cert['Title/Name'] }}</td>
                                <td>{{ cert['Organized by'] }}</td>
                                <td>{{ cert['Details'] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>


            <!-- Research Tab -->
            <div *ngIf="activeTab === 'research'">
                <!-- Journal Article Table -->
                <ng-container *ngIf="journalData && journalData.data && journalData.data.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="6">
                                    <h5>Journal Publications</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year of Publication</th>
                                <th>Details</th>
                                <th class="metrics-width">Metrics</th> 
                                <th>Self-archiving link to publication</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of journalData.data">
                                <td>{{ item['Year of Publication'] | date: 'yyyy' }}</td>
                                <td>{{ item['Details'] }}</td>
                                <td [innerHTML]="getMetrics(item)"></td>
                                <td>
                                    <a *ngIf="item['Self-archiving link to publication']"
                                        href="{{ item['Self-archiving link to publication'] }}" target="_blank">
                                        Link
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <!-- Book Table -->
                <ng-container *ngIf="booksData && booksData.data && booksData.data.length > 0">

                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Books</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year of Publication</th>
                                <th>Details</th>
                                <th>Self-archiving link to publication</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of booksData?.data">
                                <td>{{ item['Year of Publication'] | date: 'yyyy' }}</td>
                                <td>{{ item['Details'] }}</td>
                                <!-- <td><a [href]="item['Self-archiving link to publication']" target="_blank">Link</a></td> -->
                                <td>
                                    <a [href]="item['Self-archiving link to publication'] || 'https://www.sjim.edu.in/faculty.php'"
                                        target="_blank">
                                        {{ item['Self-archiving link to publication'] ? 'Link' : 'Link' }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <!-- Case Study Table -->
                <ng-container *ngIf="caseStudiesData && caseStudiesData.data && caseStudiesData.data.length > 0">

                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Case Studies</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year of Publication</th>
                                <th>Details</th>
                                <th>Self-archiving link to publication</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of caseStudiesData?.data">
                                <td>{{ item['Year of Publication'] | date: 'yyyy' }}</td>
                                <td>{{ item['Details'] }}</td>
                                <!-- <td><a [href]="item['Self-archiving link to publication']" target="_blank">Link</a></td> -->

                                <td>
                                    <a [href]="item['Self-archiving link to publication'] || 'https://www.sjim.edu.in/faculty.php'"
                                        target="_blank">
                                        {{ item['Self-archiving link to publication'] ? 'Link' : 'Link' }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <!-- Papers Presented at Conferences Table -->
                <ng-container *ngIf="conferencesData && conferencesData.data && conferencesData.data.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Papers Presented at Conferences</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year of Publication</th>
                                <th>Details</th>
                                <th>Self-archiving link to publication</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of conferencesData?.data">
                                <td>{{ item['Year of Publication'] | date: 'yyyy' }}</td>
                                <td>{{ item['Details'] }}</td>
                                <!-- <td><a [href]="item['Self-archiving link to publication']" target="_blank">Link</a></td> -->

                                <td>
                                    <a [href]="item['Self-archiving link to publication'] || 'https://www.sjim.edu.in/faculty.php'"
                                        target="_blank">
                                        {{ item['Self-archiving link to publication'] ? 'Link' : 'Link' }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <!-- Popular Press Table -->
                <ng-container *ngIf="popularPressData && popularPressData.data && popularPressData.data.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Popular Press</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year of Publication</th>
                                <th>Details</th>
                                <th>Self-archiving link to publication</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of popularPressData?.data">
                                <td>{{ item['Year of Publication'] | date: 'yyyy' }}</td>
                                <td>{{ item['Details'] }}</td>
                                <!-- <td><a [href]="item['Self-archiving link to publication']" target="_blank">Link</a></td> -->

                                <td>
                                    <a [href]="item['Self-archiving link to publication'] || 'https://www.sjim.edu.in/faculty.php'"
                                        target="_blank">
                                        {{ item['Self-archiving link to publication'] ? 'Link' : 'Link' }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>

            <!-- Consultancy Tab -->
            <div *ngIf="activeTab === 'consultancy'">
                <ng-container
                    *ngIf="consultancyProjectData && consultancyProjectData.data && consultancyProjectData.data.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Consultancy (Project)</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year</th>
                                <th>Organization it was undertaken for</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let project of consultancyProjectData?.data">
                                <td>{{ project['Year'] | date: 'yyyy' }}</td>
                                <td>{{ project['Organization it was undertaken for'] }}</td>
                                <td>{{ project['Details'] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <ng-container
                    *ngIf="consultancyProgramData && consultancyProgramData.data && consultancyProgramData.data.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>FDPs and MDPs Organized (Programs)</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year</th>
                                <th>Organization it was conducted for</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let program of consultancyProgramData?.data">
                                <td>{{ getYear(program['Year']) }}</td>
                                <td>{{ program['Organization it was conducted for'] }}</td>
                                <td>{{ program['Details'] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <ng-container *ngIf="institutesData && institutesData.data && institutesData.data.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Academic Position At Other Institutes</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year</th>
                                <th>Name of Institution</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let institute of institutesData?.data">
                                <td>{{ formatDateRange(institute['End Date'], institute['Start Date']) }}</td>
                                <td>{{ institute['Name of Institution'] }}</td>
                                <td>{{ concatenateInfo(institute['Member of'], institute['Any other Information']) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>

            <!-- Past Experience Tab -->
            <div *ngIf="activeTab === 'pastExperience'">
                <ng-container *ngIf="pastExprienceData && pastExprienceData.data && pastExprienceData.data.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Past Experience</h5>
                                </th>
                            </tr>
                            <tr>
                                <th>Position</th>
                                <th>Name of the Organization</th>
                                <th>No of Years</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let experience of pastExprienceData?.data">
                                <td>{{ experience['Position'] }}</td>
                                <td>{{ experience['Name of the Organization'] }}</td>
                                <td>{{ experience['No of Years'] }}</td>
                                <td>{{ experience['Details'] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>

            <!-- Awards And Grants Tab -->
            <div *ngIf="activeTab === 'awardsAndGrants'">
                <ng-container *ngIf=" awrdGrantsData && awrdGrantsData.data && awrdGrantsData.data.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="5">
                                    <h5>Awards and Grants</h5>
                                </th>
                            </tr>
                            <tr>
                                <th class="year-column">Year</th>
                                <th>Name of Award/Grant</th>
                                <th>Awarded by</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let award of  awrdGrantsData?.data">
                                <td>{{ award['Year'] }}</td>
                                <td>{{ award['Name of Award/Grant'] }}</td>
                                <td>{{ award['Awarded by'] }}</td>
                                <td>{{ award['Details'] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>
        </div>
    </div>
</div>