import { Component, Inject, OnInit } from '@angular/core';
import { Staff } from '../../models/staff.model';
import { StaffService } from '../../service/staff.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-staff-profile',
    imports: [CommonModule, RouterModule],
    providers: [StaffService],
    templateUrl: './staff-profile.component.html',
    styleUrl: './staff-profile.component.css'
})
export class StaffProfileComponent implements OnInit {

  activeContent: string | null = 'byDomain';
  staffList: Staff[] = [];
  nameGroups: { [key: string]: { id: string, name: string }[] } = {};
  faculty: Staff[] = [];

  constructor (@Inject(StaffService) private staffService: StaffService){}

  ngOnInit(): void {
    this.fetchAllStaff();
  }

  fetchAllStaff(): void {
    this.staffService.getAllStaff().subscribe((staffList: Staff[]) => {
      this.staffList = staffList;

      const prefixRegex = /^(Mr\.|Mrs\.|Ms\.|Dr\.|Prof\.)\s+/i;
  

      this.nameGroups = staffList.reduce((groups: { [key: string]: { id: string, name: string }[] }, staff: Staff) => {
        if (staff.name) {
          const removePrefix = staff.name.replace(prefixRegex, '').trim();
          const firstLetter = removePrefix.charAt(0).toUpperCase();
          if (!groups[firstLetter]) {
            groups[firstLetter] = [];
          }
          groups[firstLetter].push({ id: staff.id || '', name: staff.name }); 
        }
        return groups;
      }, {});
    });
  }
  

  showContent(content: string) {
    this.activeContent = content;
    if (content === 'byDomain') {
      this.fetchAllStaff();
    }
  }

}
