export class Staff implements IDepartment{
    id?: string;
    name?: string;
    department?: IDepartment | string;
    profilePicture?: string;

    constructor({
        id,
        name,
        department,
        profilePicture
    }: {
        id?: string;
        name?: string;
        department?: IDepartment | string;
        profilePicture?: string;
    }) {
        this.id = id;
        this.name = name;
        this.department = department;
        this.profilePicture = profilePicture;
    }
}

export interface IDepartment {
    id?: string;
    name?: string;
    description?: string;
}
