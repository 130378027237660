import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffService } from '../../service/staff.service';
import { IResearch } from '../../models/view-profile.model';

//  Publications in Journals
 
// Books & Book Chapters
 
// Case Studies in Public Domain
 
// Conferences
 
// Popular Press

@Component({
    selector: 'app-faculty-research',
    imports: [CommonModule],
    providers: [StaffService],
    templateUrl: './faculty-research.component.html',
    styleUrls: ['./faculty-research.component.css']
})
export class FacultyResearchComponent implements OnInit {

  researchData: IResearch[] = [];
  previousScrollPosition: number = 0;
  showScrollUpButton = false;
  isLoading: boolean = true;
  
  formId = '36rBxCwiGkjZ2aBi2';
  staffIds: string[] = [
    '2', '3', '6', '12', '17', '18', '22', '24', '25', '26', '59', '63', '65',
    '68', '74', '82', '106', '110', '111', '112', '113', '117', '121', '129'
  ];
  
  constructor(private staffService: StaffService) { }

  ngOnInit(): void {
    this.getFacultyResearchData();
  }

  getFacultyResearchData(): void {
    this.staffService.getResearchDetailsForAllStaff(this.formId, this.staffIds).subscribe(
      (response) => {
        console.log(response);
        
        // Flatten the research data and sort it by the "Year of Publication"
        this.researchData = response.flatMap(res => res.data)
          .sort((a, b) => {
            const yearA = a["Year of Publication"] ? parseInt(a["Year of Publication"]) : 0;
            const yearB = b["Year of Publication"] ? parseInt(b["Year of Publication"]) : 0;
            return yearB - yearA; // For descending order, use yearB - yearA
          });
  
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching faculty research details:', error);
        this.isLoading = false;
      }
    );
  }
  
  

  scrollToSection(sectionId: string): void {
    if (sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        // Smooth scrolling to the section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  // Listen to scroll events
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const currentScrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // Check if the user is in the bottom half of the webpage
    if (currentScrollPosition >= documentHeight / 4) {
      this.showScrollUpButton = true; // Show the button
    } else {
      this.showScrollUpButton = false; // Hide the button
    }
  }

  // Scroll to top functionality
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  

}
