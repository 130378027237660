import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environments';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Staff } from '../models/staff.model';
import { Form } from '../models/form.model';
import { IAwardResponse, ICertificationsResponse, ICProgramResponse, ICProjectResponse, IEducationResponse, IInstitutesResponse, IPExpResponse, IResearch, IResearchResponse, StaffDataResponse } from '../models/view-profile.model';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  private staffDetailApiUrl = 'https://sjim.linways.com/api/v1/staffs/';
  private formApiUrl = 'https://sjim.linways.com/api/v1/exat2/forms/';
  private staffReportApiUrl = 'https://sjim.linways.com/api/v1/exat2/forms/reports/fw8Xu8g56Snf6qGFY/';
  private formTabApiUrl = 'https://sjim.linways.com/api/v1/exat2/forms/reports/';
  // private facultyResearchApiUrl = 'https://sjim.linways.com/api/v1/exat2/forms/reports/36rBxCwiGkjZ2aBi2/';

  staffId: string[] = [
    '2', '3', '6', '12', '17', '18', '22', '24', '25', '26', '59', '63', '65',
    '68', '74', '82', '106', '110', '111', '112', '113', '117', '121', '129'
  ];

  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': `${environment.authorization}`
    })
  };

  constructor(private http: HttpClient) { }

  getAllStaff(): Observable<Staff[]> {
    const requests = this.staffId.map(staffIds =>
      this.http.get<Staff>(`${this.staffDetailApiUrl}${staffIds}?includeStaffImage=true`, this.httpOptions).pipe(
        map(response => ({
          id: response.id,
          name: response.name,
          profilePicture: response.profilePicture ? `https://sjim.linways.com${response.profilePicture}` : undefined,
          department: typeof response.department === 'object' && response.department !== null
            ? response.department.description
            : response.department,
        })),
        catchError(error => {
          console.error(`Error fetching staff with id ${staffIds}:`, error);
          return throwError(() => new Error('Failed to fetch staff details.'));
        })
      )
    );

    return forkJoin(requests).pipe(
      map(responses => responses.map(response =>
        new Staff({
          id: response.id,
          profilePicture: response.profilePicture,
          name: response.name,
          department: response.department
        })
      )),
      catchError(error => {
        console.error('Error fetching all staff:', error);
        return throwError(() => new Error('Failed to fetch all staff.'));
      })
    );
  }

  getFormsById(id: string): Observable<Form[]> {
    return this.http.get<Form[]>(`${this.formApiUrl}${id}`, this.httpOptions).pipe(
      catchError(error => {
        console.error(`Error fetching forms with id ${id}:`, error);
        return throwError(() => new Error('Failed to fetch forms by id.'));
      })
    );
  }

  getForms(): Observable<Form[]> {
    return this.http.get<Form[]>(this.formApiUrl, this.httpOptions).pipe(
      catchError(error => {
        console.error('Error fetching forms:', error);
        return throwError(() => new Error('Failed to fetch forms.'));
      })
    );
  }

  getFormDetails(staffId: string): Observable<StaffDataResponse> {
    const reportUrl = `${this.staffReportApiUrl}${staffId}`;
    const staffUrl = `${this.staffDetailApiUrl}${staffId}?includeStaffImage=true`;

    return this.http.get<StaffDataResponse>(reportUrl, this.httpOptions).pipe(
      switchMap((formResponse: StaffDataResponse) => {
        return this.http.get<Staff>(staffUrl, this.httpOptions).pipe(
          map((staffResponse: Staff) => {
            const updatedData = formResponse.data.map(item => ({
              ...item,
              profilePicture: staffResponse.profilePicture
                ? `https://sjim.linways.com${staffResponse.profilePicture}`
                : undefined
            }));
            return {
              ...formResponse,
              data: updatedData
            };
          }),
          catchError(error => {
            console.error(`Error fetching staff details for staffId ${staffId}:`, error);
            return throwError(() => new Error('Failed to fetch staff details.'));
          })
        );
      }),
      catchError(error => {
        console.error(`Error fetching form details for staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch form details.'));
      })
    );
  }

  getEducationDetails(formId: string, staffId: string): Observable<IEducationResponse> {
    const url = `${this.formTabApiUrl}${formId}/${staffId}`;
    return this.http.get<IEducationResponse>(url, this.httpOptions).pipe(
      map(response => ({
        headers: response.headers,
        data: response.data
          .map((item: any) => {
            const dateStr = item["Graduation Date"];
            console.log(`Extracted Graduation Date: ${dateStr}`); // Log the extracted date

            // Split the date string and extract the year
            const parts = dateStr.split('-'); // Split the date
            const year = parts[2]; // Extract the year as a string

            return {
              "Year": year, // Keep the year as a string
              "Name of Academic Degree": item["Name of Academic Degree"],
              "Specialization": item["Specialization"],
              "University": item["University"],
              "Details": item["Details of any award related to the degree"]
            };
          })
          .filter(item => item["Year"] && item["Year"].length === 4) // Filter out entries with invalid year strings
          .sort((a, b) => parseInt(b["Year"], 10) - parseInt(a["Year"], 10)) // Sort by year from recent to old
      })),
      catchError(error => {
        console.error(`Error fetching education details for formId ${formId} and staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch education details.'));
      })
    );
  }


  getCertificationsDetails(formId: string, staffId: string): Observable<ICertificationsResponse> {
    const url = `${this.formTabApiUrl}${formId}/${staffId}`;
    return this.http.get<ICertificationsResponse>(url, this.httpOptions).pipe(
      map(response => ({
        headers: response.headers,
        data: response.data

          .filter(item =>
            item["Type of Training"] === "Certification" || item["Type of Training"] === "Examination"
          )

          .map((item: any) => {

            const dateStr = item["Date of training"];

            const parts = dateStr.split('-');
            const year = parts[2];

            return {
              "Year": year,
              "Type of Training": item["Type of Training"],
              "Title/Name": item["Title/Name"],
              "Organized by": item["Organized by"],
              "Details": item["Details"]
            };

          })
          .filter(item => item["Year"] && item["Year"].length === 4)
          .sort((a, b) => parseInt(b["Year"], 10) - parseInt(a["Year"], 10))

      })),
      catchError(error => {
        console.error(`Error fetching certifications details for formId ${formId} and staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch certifications details.'));
      })
    );
  }

  getResearchDetails(formId: string, staffId: string): Observable<IResearchResponse> {
    const url = `${this.formTabApiUrl}${formId}/${staffId}`;
    return this.http.get<IResearchResponse>(url, this.httpOptions).pipe(
      map(response => {
        return {
          headers: response.headers,
          data: response.data.map((item: any) => {
            return {
              "Year of Publication": item["Year of Publication"],
              "Details": item["Full citation for your publication in APA format"],
              "Self-archiving link to publication": item["Self-archiving link to publication"],
              "Publication Type": item["Publication Type"],
              "Is the publication listed in Scopus?": item["Is the publication listed in Scopus?"],
              "Is  the publication listed in ABDC?": item["Is  the publication listed in ABDC?"],
              "Is the publication listed in Web-of-Science?": item["Is the publication listed in Web-of-Science?"],
              "Are any co-authors SJIM students?": item["Are any co-authors SJIM students?"]
            };
          }).filter(item => item["Year of Publication"] && item["Year of Publication"].length === 4)
            .sort((a, b) => parseInt(b["Year of Publication"], 10) - parseInt(a["Year of Publication"], 10))
        } as IResearchResponse;
      }),
      catchError(error => {
        console.error(`Error fetching research details for formId ${formId} and staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch research details.'));
      })
    );
  }

  getCProjectDetails(formId: string, staffId: string): Observable<ICProjectResponse> {
    const url = `${this.formTabApiUrl}${formId}/${staffId}`;
    return this.http.get<ICProjectResponse>(url, this.httpOptions).pipe(
      map(response => ({
        headers: response.headers,
        data: response.data
          .map((item: any) => ({
            "Year": item["Start date of consultancy"],
            "Organization it was undertaken for": item["Organization it was undertaken for"],
            "Details": item["Details of the project"]
          }))
          .sort((a, b) => new Date(b["Year"]).getTime() - new Date(a["Year"]).getTime())  // Sort by date in descending order
      })),
      catchError(error => {
        console.error(`Error fetching project details for formId ${formId} and staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch project details.'));
      })
    );
  }


  getCProgramDetails(formId: string, staffId: string): Observable<ICProgramResponse> {
    const url = `${this.formTabApiUrl}${formId}/${staffId}`;
    return this.http.get<ICProgramResponse>(url, this.httpOptions).pipe(
      map(response => ({
        headers: response.headers,
        data: response.data
          .map((item: any) => ({
            "Year": item["Start date of program"],
            "Organization it was conducted for": item["Organization it was conducted for"],
            "Details": item["Details of the program"]
          }))
          .sort((a, b) => new Date(b["Year"]).getTime() - new Date(a["Year"]).getTime())  // Sort by date in descending order
      })),
      catchError(error => {
        console.error(`Error fetching program details for formId ${formId} and staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch program details.'));
      })
    );
  }

  getInstitutesDetails(formId: string, staffId: string): Observable<IInstitutesResponse> {
    const url = `${this.formTabApiUrl}${formId}/${staffId}`;
    return this.http.get<IInstitutesResponse>(url, this.httpOptions).pipe(
      map(response => ({
        headers: response.headers,
        data: response.data.map((item: any) => ({
          "Start Date": item["Start Date"],
          "End Date": item["End Date"],
          "Name of Institution": item["Name of Institution"],
          "Member of": item["Member of"],
          "Any other Information": item["Any other Information"]
        }))
      })),
      catchError(error => {
        console.error(`Error fetching awards details for formId ${formId} and staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch awards details.'));
      })
    );
  }

  getPastExperienceDetails(formId: string, staffId: string): Observable<IPExpResponse> {
    const url = `${this.formTabApiUrl}${formId}/${staffId}`;
    return this.http.get<IPExpResponse>(url, this.httpOptions).pipe(
      map(response => ({
        headers: response.headers,
        data: response.data.map((item: any) => ({
          "Position": item["Title or Position"],
          "Name of the Organization": item["Name of the Organization"],
          "No of Years": item["No of Years"],
          "Details": item["Details of experience"]
        }))
      })),
      catchError(error => {
        console.error(`Error fetching awards details for formId ${formId} and staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch awards details.'));
      })
    );
  }

  getAwardsDetails(formId: string, staffId: string): Observable<IAwardResponse> {
    const url = `${this.formTabApiUrl}${formId}/${staffId}`;
    return this.http.get<IAwardResponse>(url, this.httpOptions).pipe(
      map(response => ({
        headers: response.headers,
        data: response.data
          .map((item: any) => {
            const dateStr = item["Date of Award/Grant"];

            const parts = dateStr.split('-');
            const year = parts[2];

            return {
              "Year": year,
              "Name of Award/Grant": item["Name of Award/Grant"],
              "Awarded by": item["Awarded by"],
              "Details": item["Details of the award/grant"]
            };
          })
          .filter(item => item["Year"] && item["Year"].length === 4)
          .sort((a, b) => parseInt(b["Year"], 10) - parseInt(a["Year"], 10))
      })),
      catchError(error => {
        console.error(`Error fetching awards details for formId ${formId} and staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch awards details.'));
      })
    );
  }

  // Function to fetch details for all staff IDs
  getResearchDetailsForAllStaff(
    formId: string,
    staffIds: string[]
  ): Observable<IResearchResponse[]> {
    const requests = staffIds.map((staffId) =>
      this.getResearchDetails(formId, staffId)
    );
    return forkJoin(requests).pipe(
      map((responses) => responses),
      catchError((error) => {
        console.error('Error fetching research details for all staff:', error);
        return throwError(() => new Error('Failed to fetch research details for all staff.'));
      })
    );
  }

  getResearchDetailsForStudents(formId: string, staffId: string): Observable<IResearchResponse> {
    const url = `${this.formTabApiUrl}${formId}/${staffId}`;
    return this.http.get<IResearchResponse>(url, this.httpOptions).pipe(
      map(response => {
        const filteredData = response.data
          .filter((item: any) => item["Are any co-authors SJIM students?"] === "Yes") // Filter based on "Yes"
          .map((item: any) => ({
            "Year of Publication": item["Year of Publication"],
            "Details": item["Full citation for your publication in APA format"]
          }))
          .filter(item => item["Year of Publication"] && item["Year of Publication"].length === 4); // Ensure valid year

        // Sort by year in descending order
        const sortedData = filteredData.sort((a, b) => parseInt(b["Year of Publication"], 10) - parseInt(a["Year of Publication"], 10));

        return {
          headers: response.headers,
          data: sortedData
        } as IResearchResponse; // Type cast to IResearchResponse
      }),
      catchError(error => {
        console.error(`Error fetching research details for formId ${formId} and staffId ${staffId}:`, error);
        return throwError(() => new Error('Failed to fetch research details.'));
      })
    );
  }


}